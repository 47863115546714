import fi from 'vuetify/src/locale/fi';
import { UserVuetifyPreset } from 'vuetify';

export default {
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: '#b30a0e',
        secondary: '#4f4f4f',
        accent: '#b30a0e',
        error: '#e60d12',
        info: '#fe6e0c',
        success: '#388538',
        warning: '#ffae00',
      },
      dark: {
        primary: '#b30a0e',
        secondary: '#4f4f4f',
        accent: '#b30a0e',
        error: '#e60d12',
        info: '#fe6e0c',
        success: '#388538',
        warning: '#ffae00',
      },
    },
  },
  lang: {
    locales: { fi },
    current: 'fi',
  },
} as Partial<UserVuetifyPreset>;
